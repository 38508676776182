import './scss/site.scss';
import init from './common';
import initPhotoSwipeFromDOM from './gallery';
import { sizeOfThings, convertImages, getCookie, setCookie } from './utils';
// import Glide from '@glidejs/glide';
import Swiper from 'swiper';
import gsap from "gsap";
import { ScrollTrigger } from "gsap/all";
import { action as FavAction, refresh } from './services/favoritos';

init();
convertImages("img.svg");

// ----------------------------- SLIDER -------------------------------
let ctnbanner = document.querySelector('.ctn-banner');
if (ctnbanner) {
    var bannerSwiper = new Swiper ('.swiper-container-banner', {
        effect: 'fade',
        grabCursor: true,
        loop: false,
        pagination: {
            el: ctnbanner.querySelector('.swiper-pagination'),
            clickable: true
        },
        navigation: {
            nextEl: ctnbanner.querySelector('.swiper-button-next'),
            prevEl: ctnbanner.querySelector('.swiper-button-prev'),
        },
        breakpoints: {
            300: {
                slidesPerView: 1,
                spaceBetween: 20,
            },
            640: {
            slidesPerView: 1,
            spaceBetween: 20,
            },
            768: {
            slidesPerView: 1,
            spaceBetween: 50,
            },
            1024: {
            slidesPerView: 1,
            spaceBetween: 100,
            },
        },
        //Events
        on: {
            // init: updateWaves,
            // sliderMove: updateTexts,
            // autoplay: updateTexts,
            // slideChangeStart: updateTexts,
            // slideChange: updateWaves
        },
    });
}
// ------------------------ sidemenu mobile ---------------------------
// let boxMenuOpen = document.querySelector('.ctn-top-head-mobile .box-menu');
// let sideMenu = document.querySelector('.ctn-side-menu');
// boxMenuOpen.addEventListener('click', (e) => {
//     sideMenu.classList.add('sidemenu-open');
//     console.log('test');
// });
// window.addEventListener('click', (e) => {
//     if (sideMenu.classList.contains('sidemenu-open') && !sideMenu.querySelector('.content-sidemenu').contains(e.target) && !boxMenuOpen.contains(e.target)) {
//         sideMenu.classList.remove('sidemenu-open');
//     }
// });

// --------------------------- galeries -------------------------------
initPhotoSwipeFromDOM('.gallery');

// -------------------------------- FAVORITOS ----------------------------------
let btnOpenFavoritos = document.querySelector('.btn-float-fav')?.addEventListener('click', e => {
    document.querySelector('.sec-favoritos')?.classList.add('sec-favoritos-open');
    document.querySelector('.overlay-fav')?.classList.add('open');
});
document.querySelectorAll('.close-fav-m, .overlay-fav')?.forEach(itemClose => {
    itemClose.addEventListener('click', e => {
        document.querySelector('.sec-favoritos')?.classList.remove('sec-favoritos-open');
        document.querySelector('.overlay-fav')?.classList.remove('open');
    });
});

FavAction(0, "get_dados", function (response) {
    refresh(response);
});

// ----------------------------- LAZY IMG LOADER -------------------------------
document.addEventListener("DOMContentLoaded", function() {
    var lazyImages = [].slice.call(document.querySelectorAll("img.lazy"));

    if ("IntersectionObserver" in window) {
        let lazyImageObserver = new IntersectionObserver(function(entries, observer) {
            entries.forEach(function(entry) {
                if (entry.isIntersecting) {
                    let lazyImage = entry.target;
                    lazyImage.src = lazyImage.dataset.src;
                    lazyImage.srcset = lazyImage.dataset.srcset;
                    lazyImage.onload = function (){
                        lazyImage.classList.remove("lazy");
                    };
                    lazyImageObserver.unobserve(lazyImage);
                }
            });
        });

        lazyImages.forEach(function(lazyImage) {
            lazyImageObserver.observe(lazyImage);
        });
    } else {
        // para navegadores que nao suportam intersection observer
        let active = false;

        const lazyLoad = function() {
            if (active === false) {
                active = true;

                setTimeout(function() {
                    lazyImages.forEach(function(lazyImage) {
                        if ((lazyImage.getBoundingClientRect().top <= window.innerHeight && lazyImage.getBoundingClientRect().bottom >= 0) && getComputedStyle(lazyImage).display !== "none") {
                            lazyImage.src = lazyImage.dataset.src;
                            lazyImage.srcset = lazyImage.dataset.srcset;
                            lazyImage.onload = function (){
                                lazyImage.classList.remove("lazy");
                            };

                            lazyImages = lazyImages.filter(function(image) {
                                return image !== lazyImage;
                            });

                            if (lazyImages.length === 0) {
                                document.removeEventListener("scroll", lazyLoad);
                                window.removeEventListener("resize", lazyLoad);
                                window.removeEventListener("orientationchange", lazyLoad);
                            }
                        }
                    });

                    active = false;
                }, 200);
            }
        };

        document.addEventListener("scroll", lazyLoad);
        window.addEventListener("resize", lazyLoad);
        window.addEventListener("orientationchange", lazyLoad);
    }
});

//--------------------------- Animations (GSAP) ------------------------------
gsap.registerPlugin(ScrollTrigger);

gsap.to(".stagger-group-top", {
    scrollTrigger: {
        trigger: ".ctn-stagger-effect",
        start: "-20px 70%",
        // end: "+=300",
        markers: false,
        // scrub: true,
        toggleActions: "play reverse play reverse"
    },
    duration: .65,
    scale: 1,
    y: 0,
    opacity: 1,
    ease: "power1.inOut", 
    stagger: {
        each: 0.12,
    }
});

// ----------------------------- SCROLL TO -------------------------------
const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
var scrollsToActions = document.querySelectorAll('.scroll-to');
scrollsToActions.forEach(elem => {
    elem.addEventListener('click', () => {
        let target = document.querySelector(elem.getAttribute('data-target'));
        var doc = document.documentElement;
        var top = (window.pageYOffset || doc.scrollTop)  - (doc.clientTop || 0);

        if (target) {
            let boundingRect = target.getBoundingClientRect();
            let heightMenu = 0;
            if (sizeOfThings()[0] <= 992) {
                EventEmitter.dispatch('close_mobile_menu');
                heightMenu = document.querySelector('div[data-controller="navbar-effect"]').getBoundingClientRect().height;
            } else {
                heightMenu = document.querySelector('div[data-controller="navbar-effect"]').getBoundingClientRect().height;
            }
            if (supportsNativeSmoothScroll) {
                window.scrollTo({
                    top: (boundingRect.top + top) - heightMenu,
                    left: 0,
                    behavior: 'smooth'
                });
            } else {
                window.scrollTo(0,(boundingRect.top + top) - heightMenu);
            }
        }
    })
});

// ----------------------------- SCROLL EVENTS -------------------------------
let floatBtn = document.querySelector('.ctn-float-btn');
if (floatBtn) {
    floatBtn.addEventListener('click', () => {
        if (supportsNativeSmoothScroll) {
            window.scrollTo({
                top: 0,
                left: 0,
                behavior: 'smooth'
            });
        } else {
            window.scrollTo(0,0);
        }
    });
}
let floatBtnWhats = document.querySelector('.ctn-float-btn-whats');
document.addEventListener('scroll', function(e) {
    // if (sizeOfThings()[0] > 992) {
        let scrollPosY = window.scrollY;
        let header = document.querySelector('header');
        let banner = document.querySelector('.ctn-banner') ?? document.querySelector('.banner-internas');
        let boundingRectHead = header.getBoundingClientRect();
        let boundingRectBann = banner ? banner.getBoundingClientRect() : boundingRectHead;
        if (scrollPosY + boundingRectHead.height > boundingRectBann.height) {
            if (!header.classList.contains('not-scroll-effect') && sizeOfThings()[0] > 992) { header.classList.add('inTop'); }
            if (floatBtn) floatBtn.classList.add('float-btn-effect');
            if (floatBtnWhats) floatBtnWhats.classList.add('float-btn-effect');
        } else {
            if (!header.classList.contains('not-scroll-effect') && sizeOfThings()[0] > 992) { header.classList.remove('inTop'); }
            if (floatBtn) floatBtn.classList.remove('float-btn-effect');
            if (floatBtnWhats) floatBtnWhats.classList.remove('float-btn-effect');
        }
    // }
});

// -------------------------- ORDENAR SELECT -----------------------------------
let ordenarListSelect = document.querySelector("select#ordenar");
if (ordenarListSelect) {
    ordenarListSelect.addEventListener('change', e => {
        let o = ordenarListSelect.querySelector('option:checked');
        window.location.href = o.value;
    });
}

// -------------------------------- ESPIAR -------------------------------------
let galerias = document.querySelectorAll('.box-galeria-d');
if (galerias && galerias.length > 0) {
    galerias.forEach(galeria => {
        let thumbs = galeria.querySelectorAll('.item-group-galeria');
        if (thumbs && thumbs.length > 0) {
            thumbs.forEach(thumb => {
                thumb.addEventListener('click', e => {
                    thumbs.forEach(thumb => { thumb.classList.remove('it-min-sel'); });
                    thumb.classList.add('it-min-sel');
                    let id = thumb.getAttribute('data-id');
                    let imgs = galeria.querySelectorAll('.item-galeria-d');
                    if (imgs && imgs.length > 0) {
                        imgs.forEach(img => {
                            img.style.display = 'none';
                            if (img.getAttribute('data-id') == id) {
                                img.style.display = 'block';
                            }
                        });
                    }
                });
            });
        }
    });
}


// -------------------------- COOKIES STUFFS -----------------------------------
var cookieOk = getCookie('close-msg-cookie');
if (cookieOk == null || cookieOk == false) {
    document.querySelector('.pv-cookie-box')?.classList.remove('hide');
}
document.querySelector('.pv-btn')?.addEventListener('click', (e) => {
    document.querySelector('.pv-cookie-box')?.classList.add('hide');
    setCookie('close-msg-cookie', true, 30);
});


// overlay loading
// document.addEventListener("DOMContentLoaded", function() {
//     document.querySelector('.overlay-loading').classList.remove('show');
// });

// start the Stimulus application
import './bootstrap';
import { EventEmitter } from './events/event_emitter';

